<template>
  <footer class="relative bg-blueGray-200 pt-8 pb-6">
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap text-center lg:text-left">
        <div class="w-full lg:w-6/12 px-4">
          <h4 class="text-3xl font-semibold">Оставайтесь на связи!</h4>
          <div class="mt-6 lg:mb-0 mb-6">
            <a
              class="px-3 py-2 mr-2 bg-white text-indigo-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none"
              href="https://www.instagram.com/svet_ake?igsh=MW9zMmd2OHI0bGs4MA=="
              target="_blank"
            >
              <i class="fab fa-instagram"></i>
            </a>
            <a
              class="px-3 py-2 mr-2 bg-white text-emerald-500 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none"
              href="https://wa.me/996709202844"
              target="_blank"
            >
              <i class="fab fa-whatsapp"></i>
            </a>
            <a
              class="px-3 py-2 mr-2 bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none"
              href="#"
              target="_blank"
            >
              <i class="fab fa-telegram-plane"></i>
            </a>
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="flex flex-wrap items-top mb-6">
            <div class="w-full lg:w-4/12 px-4 ml-auto">
              <span
                class="block uppercase text-blueGray-500 text-sm font-semibold mb-2"
              >
                Ссылки
              </span>
              <ul class="list-unstyled">
                <li>
                  <router-link to="/">
                    <a
                      class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                      href="#"
                    >
                      Главная
                    </a>
                  </router-link>
                </li>
                <li>
                  <router-link to="/services">
                    <a
                        class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                        href="#"
                    >
                      Наши услуги
                    </a>
                  </router-link>
                </li>
                <li>
                  <router-link to="/about-us">
                    <a
                        class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                        href="#"
                    >
                      О компании
                    </a>
                  </router-link>
                </li>
                <li>
                  <router-link to="/contacts">
                    <a
                        class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                        href="#"
                    >
                      Контакты
                    </a>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-6 border-blueGray-300" />
      <div
        class="flex flex-wrap items-center md:justify-between justify-center"
      >
        <div class="w-full md:w-4/12 px-4 mx-auto text-center">
          <div class="text-sm text-blueGray-500 font-semibold py-1">
            Copyright © {{ date }} SVETAKA.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      date: new Date().getFullYear(),
    };
  },
};
</script>
