import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";
import 'mosha-vue-toastify/dist/style.css';


// mouting point for the whole app

import App from "@/App.vue";


// views without layouts

import Index from "@/views/Index.vue";
import Services from "@/views/Services.vue";
import AboutUs from "@/views/AboutUs.vue";
import ContactUs from "@/views/ContactUs.vue";

// routes

const routes = [
  {
    path: "/",
    component: Index,
  },
  {
    path: "/services",
    component: Services,
  },
  {
    path: "/about-us",
    component: AboutUs,
  },
  {
    path: "/contacts",
    component: ContactUs,
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

// const router = createRouter({
//   history: createWebHistory(),
//   routes,
//   mode: 'history'
// });

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || {left: 0, top: 0}
  }
})

createApp(App).use(router).mount("#app");
