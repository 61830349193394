<template>
  <nav
    class="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow"
  >
    <div
      class="container px-4 mx-auto flex flex-wrap items-center justify-between"
    >
      <div
        class="w-full relative flex lg:w-auto lg:static lg:block lg:justify-start"
      >
        <router-link class="mr-auto" to="/">
          <a
            class="flex items-center text-blueGray-900 text-lg font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
            href="#"
          >
            ELECTRICK<span class="text-emerald-500">BISHKEK</span>

          </a>
        </router-link>
        <a
          class="bg-emerald-500 text-white cursor-pointer text-xl leading-none p-3 border border-solid border-transparent rounded-full lg:hidden outline-none focus:outline-none sm: mr-2"
          href="tel:+996709202844"
          target="_blank"
        >
          <i class="fa fa-phone-alt"></i>
        </a>
        <button
          class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
          type="button"
          v-on:click="setNavbarOpen"
        >
          <i class="fas fa-bars"></i>
        </button>
      </div>
      <div
        class="lg:flex flex-grow items-center"
        :class="[navbarOpen ? 'block' : 'hidden']"
        id="example-navbar-warning"
      >
        <ul class="flex flex-col lg:flex-row list-none mr-auto pl-10">
          <li class="flex items-center">
            <router-link to="/">
              <a
                class="hover:text-emerald-600 px-3 py-2 flex items-center text-md font-bold"
                :class="getCurrentRoute === '/' ? 'text-emerald-500' : 'text-blueGray-500'"
                href="#"
              >
                Главная
              </a>
            </router-link>
          </li>
          <li class="flex items-center">
            <router-link to="/services">
              <a
                class="hover:text-emerald-600 px-3 py-2 flex items-center text-md font-bold"
                :class="getCurrentRoute === '/services' ? 'text-emerald-500' : 'text-blueGray-500'"
                href="#"
              >
                Наши услуги
              </a>
            </router-link>
          </li>
          <li class="flex items-center">
            <router-link to="/about-us">
              <a
                class="hover:text-emerald-600 px-3 py-2 flex items-center text-md font-bold"
                :class="getCurrentRoute === '/about-us' ? 'text-emerald-500' : 'text-blueGray-500'"
                href="#"
              >
                О компании
              </a>
            </router-link>
          </li>
          <li class="flex items-center">
            <router-link to="/contacts">
              <a
                class="hover:text-emerald-600 px-3 py-2 flex items-center text-md font-bold"
                :class="getCurrentRoute === '/contacts' ? 'text-emerald-500' : 'text-blueGray-500'"
                href="#"
              >
                Контакты
              </a>
            </router-link>
          </li>
        </ul>
        <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">

          <li class="flex items-center">
            <a
                href="tel:+996709202844"
                target="_blank"
                class="bg-emerald-500 text-white active:bg-emerald-600 text-md font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
            >
              <i class="fa fa-phone-alt"></i> +996 (709) 202 844
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
/* eslint-disable */
import IndexDropdown from "@/components/Dropdowns/IndexDropdown.vue";
import logo from "@/assets/img/img.png";
import {useRoute, useRouter} from "vue-router";

export default {
  data() {
    return {
      navbarOpen: false,
      logo,
    };
  },
  methods: {
    setNavbarOpen: function () {
      this.navbarOpen = !this.navbarOpen;
    },
  },
  components: {
    IndexDropdown,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()

    return {
      route,
      router,
    }
  },
  computed: {
    getCurrentRoute() {
      return this.route.path
    }
  },
  mounted() {
    console.log(this.getCurrentRoute  )
  }
};
</script>
