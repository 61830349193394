<template>
  <div>
    <index-navbar />
    <section class="relative py-20">
      <div class="container mx-auto px-4 mt-24">
        <div class="flex flex-wrap text-center justify-center mb-5">
          <div class="w-full lg:w-6/12 px-4">
            <h2 class="font-bold text-4xl uppercase">
              Мы в социальных сетях!
            </h2>
          </div>
        </div>
        <div class="items-center flex flex-wrap">
          <div class="w-full ml-auto mr-auto px-4">
            <blockquote class="tiktok-embed" cite="https://www.tiktok.com/@__svet_ake__" data-unique-id="__svet_ake__" data-embed-from="embed_page" data-embed-type="creator" style="max-width: 780px; min-width: 288px;" >
              <section>
                <a target="_blank" href="https://www.tiktok.com/@__svet_ake__?refer=creator_embed">@__svet_ake__</a>
              </section>
            </blockquote>
          </div>
          <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
            <blockquote class="tiktok-embed" cite="https://www.tiktok.com/@__svet_ake__/video/7328661436685667586" data-video-id="7328661436685667586" style="max-width: 605px;min-width: 325px;" > <section> <a target="_blank" title="@__svet_ake__" href="https://www.tiktok.com/@__svet_ake__?refer=embed">@__svet_ake__</a> <p>шит </p> <a target="_blank" title="♬ 原聲 - Pastry - yourongl" href="https://www.tiktok.com/music/原聲-Pastry-6927152827949239042?refer=embed">♬ 原聲 - Pastry - yourongl</a> </section> </blockquote>
          </div>
          <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
            <blockquote class="tiktok-embed" cite="https://www.tiktok.com/@__svet_ake__/video/7328660302407552258" data-video-id="7328660302407552258" style="max-width: 605px;min-width: 325px;" > <section> <a target="_blank" title="@__svet_ake__" href="https://www.tiktok.com/@__svet_ake__?refer=embed">@__svet_ake__</a> <p></p> <a target="_blank" title="♬ FEEL THE GROOVE - Queens Road, Fabian Graetz" href="https://www.tiktok.com/music/FEEL-THE-GROOVE-6769046027488987137?refer=embed">♬ FEEL THE GROOVE - Queens Road, Fabian Graetz</a> </section> </blockquote>
          </div>
          <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
            <blockquote class="tiktok-embed" cite="https://www.tiktok.com/@__svet_ake__/video/7328661436685667586" data-video-id="7328661436685667586" style="max-width: 605px;min-width: 325px;" > <section> <a target="_blank" title="@__svet_ake__" href="https://www.tiktok.com/@__svet_ake__?refer=embed">@__svet_ake__</a> <p>шит </p> <a target="_blank" title="♬ 原聲 - Pastry - yourongl" href="https://www.tiktok.com/music/原聲-Pastry-6927152827949239042?refer=embed">♬ 原聲 - Pastry - yourongl</a> </section> </blockquote>
          </div>
        </div>
      </div>
    </section>

    <section class="relative block bg-blueGray-800">
      <div class="container mx-auto px-4 lg:pt-24 lg:pb-64 pt-20 pb-32">
        <div class="flex flex-wrap text-center justify-center">
          <div class="w-full lg:w-6/12 px-4">
            <h2 class="text-4xl font-bold text-white">
              Почему мы?
            </h2>
<!--            <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-400">-->
<!--              Наши электрики - это команда опытных специалистов, готовых решить любые задачи с электротехникой.-->
<!--              Мы ценим ваше доверие и стремимся превзойти ваши ожидания в каждом проекте.-->
<!--              Выбирая нас, вы выбираете надежного партнера для вашего дома или бизнеса.-->
<!--            </p>-->
          </div>
        </div>
        <div class="flex flex-wrap mt-12 justify-center">
          <div class="w-full lg:w-3/12 px-4 text-center">
            <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
            >
              <i class="fas fa-medal text-xl"></i>
            </div>
            <h6 class="text-xl mt-5 font-semibold text-white">
              Профессионализм и опыт
            </h6>
            <p class="mt-2 mb-4 text-blueGray-400">
              Наши электрики обладают богатым опытом и профессиональными навыками,
              что позволяет им оперативно и качественно решать любые электротехнические задачи.
            </p>
          </div>
          <div class="w-full lg:w-3/12 px-4 text-center">
            <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
            >
              <i class="fas fa-lock text-xl"></i>
            </div>
            <h5 class="text-xl mt-5 font-semibold text-white">
              Надежность и безопасность
            </h5>
            <p class="mt-2 mb-4 text-blueGray-400">
              Мы придерживаемся строгих стандартов безопасности, гарантируя надежное и безопасное выполнение всех работ.
              Ваша безопасность – наш приоритет.
            </p>
          </div>
          <div class="w-full lg:w-3/12 px-4 text-center">
            <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
            >
              <i class="fas fa-check text-xl"></i>
            </div>
            <h5 class="text-xl mt-5 font-semibold text-white">
              Гарантия качества
            </h5>
            <p class="mt-2 mb-4 text-blueGray-400">
              Мы уверены в качестве наших услуг и предоставляем гарантию на выполненные работы.
              Вы можете быть уверены, что ваше электротехническое оборудование будет функционировать безупречно после нашего вмешательства.
            </p>
          </div>
          <div class="w-full lg:w-3/12 px-4 text-center">
            <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
            >
              <i class="fas fa-clock text-xl"></i>
            </div>
            <h5 class="text-xl mt-5 font-semibold text-white">
              Быстрое реагирование
            </h5>
            <p class="mt-2 mb-4 text-blueGray-400">
              Мы ценим ваше время и стремимся оперативно откликаться на ваши запросы.
              Наши электрики готовы приехать к вам в кратчайшие сроки и решить проблему эффективно.
            </p>
          </div>
        </div>
      </div>
    </section>

    <footer-component />
  </div>
</template>
<script>
/* eslint-disable */
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
import { vMaska } from "maska"
import {createToast} from "mosha-vue-toastify";
import axios from 'axios'
import { VueRecaptcha } from 'vue-recaptcha'

import services1 from "@/assets/img/services1.jpg";
import services2 from "@/assets/img/services3.jpg";
import services3 from "@/assets/img/services4.jpg";
import services4 from "@/assets/img/services5.jpg";
import services5 from "@/assets/img/services6.jpg";
import services6 from "@/assets/img/services7.jpeg";

import mainImg from "@/assets/img/header.jpeg";

export default {
  data() {
    return {
      mainImg,
      services1,
      services2,
      services3,
      services4,
      services5,
      services6,

      api: axios.create({
        baseURL: 'https://api.telegram.org/'
      }),

      name: '',
      phoneNumber: '',
      address: '',
      description: '',

      //Telegram BOT CONFIG
      token: '7173635452:AAEuvxXjP6HF3cHmSVA_pLbr8gbCVTpwc1k',
      chatId: -1002075741193,

      siteKey: '6LdVIIgpAAAAAJqF47XEwczCjMM51to2Vu1pBBxN',
      lang: 'ru',
      isVerified: false,
    };
  },
  directives: { maska: vMaska },
  components: {
    IndexNavbar,
    FooterComponent,
    VueRecaptcha,
  },
  methods: {
    handleSuccess() {
      this.isVerified = true
    },
    submit() {
      if(!this.isVerified){
        createToast({
          title: 'Пройдите проверку ReCapcha',
        }, {
          type: 'warning',
          position: 'top-right',
          showIcon: true,
        })
      } else {
        let fullMessage = 'Имя: ' + this.name + '\n'
            + 'Номер телефона: ' + this.phoneNumber + '\n'
            + 'Примерный адрес: ' + this.address + '\n'
            + 'Описание: ' + this.description + '\n'


        this.api.post(`bot${this.token}/sendMessage`, {}, {
          params: {
            chat_id: this.chatId,
            text: String(fullMessage),
          }
        }).then(
            response => {
              this.name = ''
              this.phoneNumber = ''
              this.address = ''
              this.description = ''
              this.isVerified = false
              createToast({
                title: 'Успешно!',
                description: 'Мы отправили ваш номер мастерам. В ближайшее время с вами свяжутся.',
              }, {
                type: 'success',
                position: 'top-right',
                showIcon: true,
              })
            },
            error => {
              createToast({
                title: 'Произошла ошибка!',
                description: 'Просим прощения. В ближайшее время мы исправим возникшую проблему.',
              }, {
                type: 'danger',
                position: 'top-right',
                showIcon: true,
              })
            }
        )
      }
    }
  },
  mounted() {
    window.scrollTo(0,0);
  }
};
</script>
