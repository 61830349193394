<template>
  <div>
    <index-navbar />
    <section class="pt-12 pb-40 relative bg-blueGray-100">

      <div class="justify-center text-center flex flex-wrap mt-24">
        <div class="w-full md:w-8/12 px-6 md:px-4">
          <h2 class="font-bold text-4xl uppercase">Наши услуги</h2>
        </div>
      </div>

      <div class="flex flex-wrap md:px-12 mx-4 justify-center pt-12">
        <div
            class="relative flex flex-col bg-white min-w-0 break-words w-full mb-6 shadow-lg rounded"
        >
          <div class="block w-full overflow-x-auto">
            <!-- Projects table -->
            <table class="items-center w-full bg-transparent">
              <thead>
              <tr class="border border-solid border-emerald-700">
                <th
                    class="bg-blueGray-50 text-emerald-500 px-6 align-middle py-3 text-2xl uppercase whitespace-nowrap font-semibold text-left"
                >
                  Наименование услуги
                </th>
                <th
                    class="bg-blueGray-50 text-emerald-500 px-6 align-middle py-3 text-2xl uppercase whitespace-nowrap font-semibold text-left"
                >
                  Описание
                </th>
                <th
                    class="bg-blueGray-50 text-emerald-500 px-6 align-middle py-3 text-2xl uppercase whitespace-nowrap font-semibold text-left"
                >

                </th>
              </tr>
              </thead>
              <tbody class="pt-2">
              <tr class="border border-emerald-700" v-for="item in services" :key="item.name">
                <th
                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 p-4 text-left flex items-center"
                >
                  <span
                      class="font-semibold text-lg text-blueGray-700"
                  >
                    {{ item.name }}
                  </span>
                </th>
                <td
                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-md p-4"
                >
                  {{ item.description }}
                </td>
                <td
                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-md p-4 whitespace-nowrap"
                >
                  <a
                    href="tel:+996709202844"
                    target="_blank"
                    class="bg-emerald-500 text-white active:bg-emerald-600 text-md font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                  >
                    <i class="fa fa-phone-alt"></i>
                  </a>
                  <a
                    href="https://wa.me/996709202844"
                    target="_blank"
                    class="bg-emerald-500 text-white active:bg-emerald-600 text-md font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                  >
                    <i class="fab fa-whatsapp"></i>
                  </a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>

    <section class="relative block py-24 lg:pt-0 bg-blueGray-800">
      <div class="container mx-auto px-4">
        <div class="flex flex-wrap justify-center pt-20">
          <div class="w-full lg:w-6/12 px-4">
            <div
                class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200"
            >
              <div class="flex-auto p-5 lg:p-10">
                <h4 class="text-2xl font-semibold">
                  Хотите вызвать мастера на дом?
                </h4>
                <p class="leading-relaxed mt-1 mb-4 text-blueGray-500">
                  Мы свяжемся с вами в ближайшее время
                </p>
                <div class="flex-auto pt-0">
                  <form @submit.prevent="submit">

                    <div class="flex flex-wrap">
                      <div class="w-full lg:w-12/12">
                        <div class="relative w-full mb-3">
                          <label
                              class="block text-blueGray-600 text-xs font-bold mb-1"
                          >
                            Ваше имя
                          </label>
                          <input
                              type="text"
                              class="border-0 px-3 py-3 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Как к вам обращаться?"
                              v-model="name"
                          />
                        </div>
                      </div>
                      <div class="w-full lg:w-12/12">
                        <div class="relative w-full mb-3">
                          <label
                              class="block text-blueGray-600 text-xs font-bold mb-1"
                          >
                            Номер для связи
                          </label>
                          <input
                              class="border-0 px-3 py-3 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="+996(000)000-000"
                              v-maska data-maska="+996(###)###-###"
                              v-model="phoneNumber"
                              required
                          />
                        </div>
                      </div>
                      <div class="w-full lg:w-12/12">
                        <div class="relative w-full mb-3">
                          <label
                              class="block text-blueGray-600 text-xs font-bold mb-1"
                          >
                            Примерный адрес или район
                          </label>
                          <input
                              type="text"
                              class="border-0 px-3 py-3 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Например: Верхний Джал..."
                              v-model="address"
                          />
                        </div>
                      </div>
                      <div class="w-full lg:w-12/12">
                        <div class="relative w-full mb-3">
                          <label
                              class="block text-blueGray-600 text-xs font-bold mb-1"
                          >
                            Описание работы
                          </label>
                          <textarea
                              type="text"
                              class="border-0 px-3 py-3 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              rows="4"
                              placeholder="Пожалуйста опишите проблему. Например: Сгорела розетка, пропало электричество в доме и тд. ..."
                              v-model="description"
                          >
                        </textarea>
                        </div>
                      </div>
                      <div class="w-full flex justify-center mb-2 sm:px-0 md:px-4">
                        <VueRecaptcha
                            :sitekey="siteKey"
                            :load-recaptcha-script="true"
                            :language="lang"
                            @verify="handleSuccess"
                        ></VueRecaptcha>
                      </div>
                      <div class="w-full lg:w-12/12">
                        <div class="relative w-full mb-3">
                          <button
                              class="mt-3 md:mt-0 text-white font-bold py-4 px-6 rounded outline-none focus:outline-none bg-emerald-500 active:bg-emerald-600 uppercase text-md shadow hover:shadow-lg ease-linear transition-all duration-150 w-full"
                              type="submit">
                            Вызвать мастера
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer-component />
  </div>
</template>
<script>
/* eslint-disable */
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
import { vMaska } from "maska"
import {createToast} from "mosha-vue-toastify";
import axios from 'axios'
import { VueRecaptcha } from 'vue-recaptcha'

import mainImg from "@/assets/img/header.jpeg";

export default {
  data() {
    return {
      mainImg,

      api: axios.create({
        baseURL: 'https://api.telegram.org/'
      }),

      name: '',
      phoneNumber: '',
      address: '',
      description: '',

      //Telegram BOT CONFIG
      token: '7173635452:AAEuvxXjP6HF3cHmSVA_pLbr8gbCVTpwc1k',
      chatId: -1002075741193,

      siteKey: '6LdVIIgpAAAAAJqF47XEwczCjMM51to2Vu1pBBxN',
      lang: 'ru',
      isVerified: false,

      services: [
        {
          name: "Установка и ремонт электропроводки",
          description: "Установка новой электропроводки, а также поиск и устранение неисправностей в существующей системе."
        },
        {
          name: "Установка и замена электрических розеток и выключателей",
          description: "Установка новых розеток, выключателей, а также замена старых на более современные и функциональные."
        },
        {
          name: "Монтаж и настройка электронагревательного оборудования",
          description: "Установка и настройка систем электронагрева, таких как водонагреватели, обогреватели и теплые полы."
        },
        {
          name: "Установка систем освещения",
          description: "Установка различных видов осветительных приборов, в том числе светодиодных ламп, люстр, бра и прожекторов."
        },
        {
          name: "Техническое обслуживание и диагностика электрооборудования",
          description: "Проведение регулярного технического обслуживания, а также диагностика и проверка работы электрооборудования."
        },
        {
          name: "Установка и настройка систем видеонаблюдения",
          description: "Монтаж камер видеонаблюдения и настройка системы для обеспечения безопасности вашего дома или бизнеса."
        },
        {
          name: "Установка и обслуживание систем сигнализации",
          description: "Установка и настройка систем сигнализации для защиты от вторжений и обеспечения безопасности помещения."
        },
        {
          name: "Установка и обслуживание систем автоматизации",
          description: "Установка и настройка умных систем, позволяющих автоматизировать управление освещением, отоплением и другими устройствами в доме."
        },
        {
          name: "Ремонт и замена электрооборудования",
          description: "Ремонт и замена поврежденного или устаревшего электрооборудования для обеспечения нормальной работы системы."
        }
      ]
    };
  },
  directives: { maska: vMaska },
  components: {
    IndexNavbar,
    FooterComponent,
    VueRecaptcha,
  },
  methods: {
    handleSuccess() {
      this.isVerified = true
    },
    submit() {
      if(!this.isVerified){
        createToast({
          title: 'Пройдите проверку ReCapcha',
        }, {
          type: 'warning',
          position: 'top-right',
          showIcon: true,
        })
      } else {
        let fullMessage = 'Имя: ' + this.name + '\n'
            + 'Номер телефона: ' + this.phoneNumber + '\n'
            + 'Примерный адрес: ' + this.address + '\n'
            + 'Описание: ' + this.description + '\n'


        this.api.post(`bot${this.token}/sendMessage`, {}, {
          params: {
            chat_id: this.chatId,
            text: String(fullMessage),
          }
        }).then(
            response => {
              this.name = ''
              this.phoneNumber = ''
              this.address = ''
              this.description = ''
              this.isVerified = false
              createToast({
                title: 'Успешно!',
                description: 'Мы отправили ваш номер мастерам. В ближайшее время с вами свяжутся.',
              }, {
                type: 'success',
                position: 'top-right',
                showIcon: true,
              })
            },
            error => {
              createToast({
                title: 'Произошла ошибка!',
                description: 'Просим прощения. В ближайшее время мы исправим возникшую проблему.',
              }, {
                type: 'danger',
                position: 'top-right',
                showIcon: true,
              })
            }
        )
      }
    }
  },
  mounted() {
    window.scrollTo(0,0);
  }
};
</script>
